























































































import Vue from 'vue';
import AppMain from '@/components/AppMain.vue';
import AppForm from '@/components/AppForm.vue';
import USER from '@/graphql/queries/User.graphql';
import PAY_RATE_CREATE from '@/graphql/mutations/PayRateCreate.graphql';
import { required, decimal } from 'vuelidate/lib/validators';
import _pick from 'lodash/pick';
import _isEqual from 'lodash/isEqual';
import validationMessages from '@/utils/validationMessages';
import { CODES, USER_ROLES } from '@/utils/constants';
import {
  User,
  PayRateCreateMutationVariables,
  UserQueryVariables,
} from '@/types/schema';
import { ApolloQueryResult } from 'apollo-client';
import { GraphQLError } from 'graphql';

export default Vue.extend({
  name: 'AdminUsersPayRate',
  components: {
    AppMain,
    AppForm,
  },
  data() {
    return {
      loading: false,
      errors: [] as GraphQLError[],
      form: {
        route: '' as string | number,
        pickup: '' as string | number,
        deliveryShort: '' as string | number,
        deliveryLong: '' as string | number,
        pumpHose: '' as string | number,
        detention: '' as string | number,
      },
      user: {} as User,
    };
  },
  computed: {
    ratesAreUnchanged(): boolean {
      const rateKeys = [
        'route',
        'pickup',
        'deliveryShort',
        'deliveryLong',
        'pumpHose',
        'detention',
      ];
      const { currentPayRate: userRate } = this.user;
      const { form: formRate } = this;
      const currentPayRate = _pick(userRate, rateKeys);
      const updatedPayRate = _pick(formRate, rateKeys);
      return _isEqual(currentPayRate, updatedPayRate);
    },
  },
  validations() {
    return {
      form: {
        route: { required, decimal },
        pickup: { required, decimal },
        deliveryShort: { required, decimal },
        deliveryLong: { required, decimal },
        pumpHose: { required, decimal },
        detention: { required, decimal },
      },
    };
  },
  methods: {
    validationMessages,
    fillForm(user: User) {
      if (!user) return;
      const { currentPayRate: rate } = user;
      if (!rate) return;
      this.form = {
        route: rate.route || '',
        pickup: rate.pickup || '',
        deliveryShort: rate.deliveryShort || '',
        deliveryLong: rate.deliveryLong || '',
        pumpHose: rate.pumpHose || '',
        detention: rate.detention || '',
      };
    },
    async savePayRate() {
      this.$v.$touch();
      if (this.$v.$invalid || this.ratesAreUnchanged) return;

      try {
        this.loading = true;
        this.errors = [];
        await this.$apollo.mutate({
          mutation: PAY_RATE_CREATE,
          variables: {
            input: {
              userId: this.user.id,
              payRate: {
                route: this.form.route,
                pickup: this.form.pickup,
                deliveryShort: this.form.deliveryShort,
                deliveryLong: this.form.deliveryLong,
                pumpHose: this.form.pumpHose,
                detention: this.form.detention,
              },
            },
          } as PayRateCreateMutationVariables,
        });
        this.$router.replace({ name: 'admin-users' });
        this.$notify({
          text: 'Pay rate saved!',
          type: 'success',
          duration: 6000,
        });
      } catch ({ graphQLErrors }) {
        this.errors = graphQLErrors;
      } finally {
        this.loading = false;
      }
    },
  },
  apollo: {
    user: {
      query: USER,
      variables(): UserQueryVariables {
        return {
          id: this.$route.params.id,
        };
      },
      result({ data }: ApolloQueryResult<{ user: User }>) {
        const { user } = data || {};

        if (!user || user.role === USER_ROLES.COOP) {
          this.$router.replace({ name: 'admin-users' });
        }

        this.fillForm(data.user);
      },
      error({ graphQLErrors }) {
        const [gqlError] = graphQLErrors;
        if (gqlError?.extensions?.code === CODES.NOT_FOUND) {
          this.$router.replace({ name: 'admin-users' });
        }
      },
      skip(): boolean {
        return !this.$route.params.id;
      },
    },
  },
});
